import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import View from './View'
import { colors } from './Text'
import NativeImage from 'components/NativeImage'
import WebImage from 'components/WebImage'
import FontIcon from 'components/FontIcon'

import Platform from 'lib/Platform'
import { styles, size } from 'styles'
import Configs from 'configs/Configs'
import { useSelector } from 'react-redux'
import { capture } from 'sentry'

export const bgColor = styles.color.greyLight

const Image = props => {

  const {
    id,
    uri,
    path,
    style,
    backgroundColor = bgColor,
    resizeMode,
    isFullSize,
    onLoadEnd,
    ...rest
  } = props

  const image = useSelector(state => state.entities?.images?.[id]) || null
  const [source, setSource] = useState(null)

  useEffect(()=> {
    let initialSource
    if(image){ // when the image id is given

      if(isFullSize){
        if(image.public_url){
          initialSource = { uri: image.public_url }
        } else {
          initialSource = { uri: Configs.baseUrl + image.path }
        }
      }else if(image.thumbnail_public_url){
        initialSource = { uri: image.thumbnail_public_url }
      }else if(image.thumbnail_path){
        initialSource = { uri: Configs.baseUrl + image.thumbnail_path }
      }else if(image.public_url){
        initialSource = { uri: image.public_url }
      }else{
        initialSource = { uri: Configs.baseUrl + image.path }
      }
    }

    // those will overwrite if given with id
    if(path){ // can be directly given from EditGood
      initialSource = { uri: Configs.baseUrl + path }
    }else{
      if(typeof uri === 'string' && uri.length > 0){
        initialSource = { uri: uri }
      }else if(typeof uri === 'number'){
        initialSource = uri
      }
    }
    setSource(initialSource)
  }, [id, path, uri])

  const isNoImage = !image?.id && !path && !uri

  const handleError = (e)=> {
    if(image && image.thumbnail_path){
      setSource({ uri: Configs.baseUrl + image.path })
    }
    capture(e)
  }

  return (
    <View center style={{
      width: '100%',
      height: '100%',
      backgroundColor,
      overflow: 'hidden',
      ...style,
    }}>
      {isNoImage ? (
        <FontIcon name='image-light' size='md' color={colors.label} />
      ) : (
        Platform.OS === 'web' ? (
          <WebImage
            resizeMode={resizeMode}
            source={source}
            onError={handleError}
            style={style}
            {...rest}
          />
        ) : (
          <NativeImage
            style={{
              width: '100%',
              height: '100%',
            }}
            resizeMode={resizeMode}
            source={source}
            onError={handleError}
            onLoadEnd={onLoadEnd}
            {...rest}
          />
        )
      )}
    </View>
  )
}

Image.propTypes = {
  id: PropTypes.number,
  uri: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.number]
  ),
  path: PropTypes.string,
  style: PropTypes.object,
  backgroundColor: PropTypes.string,
  resizeMode: PropTypes.string,
  isFullSize: PropTypes.bool,
}

export default Image
