import React from 'react'
import { PropTypes } from 'prop-types'
import View from 'components/View'
import TouchableOpacity from 'components/TouchableOpacity'

import { styles } from 'styles'

const Container = ({onPress, children})=> {
  return onPress ? (
    <TouchableOpacity
      onPress={onPress}
      style={{
        flex: 1,
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      {children}
    </TouchableOpacity>
  ) : (
    <View flex row vcenter style={{height: '100%'}}>
      {children}
    </View>
  )
}

Container.propTypes = {
  onPress: PropTypes.func,
  children: PropTypes.node.isRequired,
}

const MenuItem = ({
  children,
  leftIcon,
  onPress,
  borderless,
  rightIcon,
}) => {

  return (
    <View row vcenter spaceBetween style={{
      minHeight: styles.height.one.height,
      ...(borderless ? {} : styles.border.bottom),
      paddingLeft: styles.space.lg,
      paddingTop: styles.space.sm,
      paddingBottom: styles.space.sm,
      width: '100%',
    }}>
      <Container onPress={onPress}>
        {leftIcon ? (
          <View style={{marginRight: styles.space.sm}}>
            {leftIcon}
          </View>
        ) : null}
        {children}
      </Container>
      {rightIcon ? (
        <View style={{marginRight: styles.space.sm}}>
          {rightIcon}
        </View>
      ) : null}
    </View>
  )

}

MenuItem.propTypes = {
  children: PropTypes.node.isRequired,
  leftIcon: PropTypes.node,
  onPress: PropTypes.func,
  borderless: PropTypes.bool,
  rightIcon: PropTypes.node,
}

export default MenuItem
