import React from 'react'
import { PropTypes } from 'prop-types'
import View from 'components/View'
import ScrollView from 'components/ScrollView'

import { styles } from 'styles'
import useColorScheme from 'hooks/useColorScheme'

const DialogBox = props => {

  const theme = useColorScheme()

  return (
    <ScrollView style={{
      margin: styles.space.lg,
      ...(props.paddingless ? {} : { padding: styles.space.lg, }),
      backgroundColor: styles.color[theme].background,
      ...styles.shadow,
      ...styles.border.box,
      borderRadius: styles.radius.sm,
      flexGrow: 0,
      ...props.style
    }}>
      {props.children}
    </ScrollView>
  )

}

DialogBox.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  paddingless: PropTypes.bool,
}

export default DialogBox
