import React from 'react'
import TouchableOpacity from 'components/TouchableOpacity'
import Text from './Text'
import View from './View'
import ActivityIndicator from 'components/ActivityIndicator'

import { styles } from 'styles'
import useColorScheme from 'hooks/useColorScheme'
import { useLinkTo } from '@react-navigation/native'
import { parseToPath } from 'navigation/LinkingConfiguration'
import analytics from 'lib/analytics'

const Button = props => {
  const theme = useColorScheme()
  const linkTo = useLinkTo()

  const {
    onPress = ()=>{},
    style,
    leftIcon,
    label,
    inverted,
    loading,
    marginless,
    borderless,
    to,
    params,
    danger,
    nativeID,
    size='md',
  } = props

  const disabled = loading || props.disabled

  let backgroundColor = styles.color[theme].primary
  let borderColor = styles.color[theme].primary
  let textInverted = true
  let textColor = '#000'

  if(inverted) {
    backgroundColor = styles.color[theme].background
    borderColor = styles.color[theme].primary
    textInverted = false // make it visible
    textColor= borderColor
  }

  if(danger) {
    borderColor = styles.color.danger
    textColor = styles.color.danger
  }

  if(disabled) {
    backgroundColor = styles.color.grey
    borderColor = backgroundColor
    textColor = styles.color[theme].background
  }

  const handlePress = ()=> {
    if(to){
      linkTo(parseToPath(to, params))
      onPress?.()
    }else{
      onPress?.()
    }
  }

  return (
    <TouchableOpacity
      nativeID={nativeID}
      onPress={disabled ? ()=> {} : handlePress}
      activeOpacity={0.4}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        backgroundColor,
        borderWidth: borderless ? 0 : styles.width.hairlineWidth,
        borderColor,
        borderRadius: styles.radius.sm,
        padding: styles.size.tiny,
        minHeight: styles.size[size] * 2,
        ...(marginless ? {} : { marginTop: styles.space.lg }),
        ...style,
      }}
    >
      {loading ? (
        <View style={{ marginRight: styles.space.sm }}>
          <ActivityIndicator
            inverted={textInverted}
          />
        </View>
      ) : null}
      {leftIcon ? (
        <View style={{ marginRight: styles.space.sm }}>
          {leftIcon}
        </View>
      ) : null}
      <Text
        inverted={textInverted}
        color={textColor}
        size={size}
      >
        {label}
      </Text>
    </TouchableOpacity>
  )

}

import { PropTypes } from 'prop-types'
Button.propTypes = {
  label: PropTypes.string, // could be icon only
  loading: PropTypes.bool.isRequired, // make it user friendly
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
  leftIcon: PropTypes.object,
  style: PropTypes.object,
  inverted: PropTypes.bool,
  to: PropTypes.string,
  params: PropTypes.object,
  marginless: PropTypes.bool,
  borderless: PropTypes.bool,
  danger: PropTypes.bool,
  nativeID: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])
}

export default Button
