import React from 'react'
import { PropTypes } from 'prop-types'

import Text from 'components/Text'
import View from 'components/View'
import Help from 'components/Help'

import { styles } from 'styles'
import useColorScheme from 'hooks/useColorScheme'
import { t } from '@/i18n'

const Label = props => {

  const theme = useColorScheme()

  const {
    text,
    inverted,
    containerStyle,
    required,
    optional,
    help,
    link,
    size='sm'
  } = props

  return (
    <View row vcenter style={{
      marginTop: styles.space.md,
      marginBottom: styles.space.sm,
      ...containerStyle,
    }}>
      <Text label size={size}
        inverted={inverted}
        shadow={inverted}
        link={link}
      >
        {text}
      </Text>

      {required ? (
        <View style={{
          marginLeft: styles.space.sm,
        }}>
          <Text size={size} color={styles.color[theme].error}>{t('general.required')}</Text>
        </View>
      ) : null}

      {optional ? (
        <View style={{
          marginLeft: styles.space.sm,
        }}>
          <Text size={size} color={styles.color.grey}>({t('general.optional')})</Text>
        </View>
      ) : null}

      <Help help={help} size={size} />
    </View>
  )

}

Label.propTypes = {
  text: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  containerStyle: PropTypes.object,
  required: PropTypes.bool,
  help: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
}

export default Label
