import { sync } from 'features/sync'
import DateTime from 'lib/DateTime'
import { capture } from 'sentry'

export const setValue = (value) => {
  return (dispatch) => {
    dispatch({
      type: types.setValue,
      value,
    })
  }
}

export const setLoading = (loading) => {
  return (dispatch) => {
    dispatch({
      type: types.setLoading,
      loading
    })
  }
}

export const setBookingValue = (value) => {
  return (dispatch) => {
    dispatch({
      type: types.setBookingValue,
      value,
    })
  }
}

export const clear = () => {
  return (dispatch) => {
    dispatch({ type: types.clear })
  }
}

export const setSource = (source) => {
  return (dispatch) => {
    dispatch({ type: types.setSource, source })
  }
}

export const searchGoods = () => {
  return async(dispatch, getState) => {
    try{
      p('features/searches#searchGoods')
      const query = getState().searches.value
      const loading = getState().searches.loading
      const page = getState().searches.page || 1
      if(loading) return null

      setLoading(true)(dispatch)

      const today = DateTime.local().toFormat('yyyy-MM-dd')
      const result = await sync.graphql({
        query: `{
          goods(
            match: "${encodeURIComponent(query)}",
            page: ${page}
          ){
            id,
            title,
            is_hidden: isHidden,
            thumbnail_url: thumbnailUrl,
            quantity,
            bookings(dateFrom: "${today}", dateTo: "${today}"){
              id, date, start_at: startAt, end_at: endAt, cancelled, returned_at: returnedAt
            },
            price_per_day: pricePerDay,
            price_per_week: pricePerWeek,
            price_per_month: pricePerMonth,
            price_per_hour: pricePerHour,
            is_hourly: isHourly,
            for_reference_only: forReferenceOnly,
          }
        }`
      })(dispatch, getState)
      if(result?.data?.goods){
        const goodIds = result.data.goods.map(g => g.id)
        dispatch({ type: types.appendGoodIds, goodIds })
        dispatch({ type: types.incrementPage })
        dispatch({ type: types.prependHistory })
      }
      setLoading(false)(dispatch)
      return result
    }catch(err){
      ppError(err)
      capture(err)
    }
  }
}

export const searches = {
  setValue,
  searchGoods,
  clear,
  setSource,
}

export const types = {
  setValue: 'searches/setValue',
  appendGoodIds: 'searches/appendGoodIds',
  setLoading: 'searches/setLoading',
  clear: 'searches/clear',
  incrementPage: 'searches/incrementPage',
  setBookingValue: 'searches/setBookingValue',
  prependHistory: 'searches/prependHistory',
  setSource: 'searches/setSource',
}

const initialState = {
  value: '',
  // array of result
  goodIds: [],
  loading: undefined,
  page: 1,
  // for bookings search
  bookingValue: '',
  history: [],
  source: undefined, // barcode
}


export default (state = initialState, action) => {
  switch (action.type) {
    case types.setValue: {
      // reset page to 1 when the query has changed
      const isChanged = state.value !== action.value
      const page = isChanged ? 1 : state.page
      const goodIds = isChanged ? [] : state.goodIds
      return{
        ...state,
        value: action.value,
        page, goodIds,
      }
    }

    case types.appendGoodIds: {
      return{
        ...state,
        goodIds: [
          ...state.goodIds,
          ...action.goodIds
        ]
      }
    }

    case types.setLoading: {
      return{
        ...state,
        loading: action.loading
      }
    }

    case types.incrementPage: {
      const current = state.page || 1
      return{
        ...state,
        page: current + 1
      }
    }

    case types.setBookingValue: {
      return {
        ...state,
        bookingValue: action.value
      }
    }

    case types.prependHistory: {
      // ignore blank
      if(!state.value) return state

      const current = state.history || []
      // should be unique
      const found = current?.find(h => h === state.value)
      // put the found item at the top
      if(found){
        const next = current.filter(h => h !== found)
        return{
          ...state,
          history: [found, ...next]
        }
      }

      // max to 5
      const next = [ state.value, ...current ].slice(0, 5)
      return {
        ...state,
        history: next
      }
    }

    case types.setSource: {
      return {
        ...state,
        source: action.source
      }
    }

    case types.clear: {
      // clear the state except history
      return {
        ...initialState,
        history: state.history
      }
    }

    default: {
      return state
    }
  }
}
