import React from 'react'
import View from 'components/View'
import styles from 'styles'
import TouchableOpacity from 'components/TouchableOpacity'
import useDynamicGrid from 'hooks/useDynamicGrid'

const Background = ({
  children,
  onPress,
  style,
})=> {

  const { width } = useDynamicGrid({ size: 12 })

  return(
    <View flex center>
      <TouchableOpacity id='touchable-background' style={{
        flex: 1,
        width,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
        padding: styles.space.xl,
        ...style,
      }}
        onPress={onPress}
        activeOpacity={0.9}
      >
        {children}
      </TouchableOpacity>
    </View>
  )
}

export default Background
