import AppIcon from '@/components/AppIcon'
import {
  Platform,
  StyleSheet,
  View,
} from 'react-native'
import Link, { To } from '@/components/v2/Link'
import { useSelector } from 'react-redux'
import jwt from '@/utils/jwt'
import { RootState } from '@/store'
import { space } from '@/styles/constants'
import { isExpired } from '@/features/auth'
import { t } from '@/i18n'

const styles = StyleSheet.create({
  container: Platform.select({
    web: {
      // web does not have extra space on the left
      marginLeft: space[2],
    },
    default: {}
  }),
  link: {
    alignItems: 'center',
    justifyContent: 'center',
  }
})

const RootIcon = ()=> {

  const token = useSelector((state: RootState) => state.auth.token)
  const isExpiredToken = useSelector(isExpired)
  const isLoggedIn = !!token

  let root: To = '/home'
  // root to group page if logged in with membership card
  const isLimitedToken = jwt.isLimitedToken(token)
  if(isLimitedToken) {
    const parsed = jwt.parse(token)
    if(parsed){
      root = `/-/${parsed.authorizable_id}`
    }
  }

  const defaultTabName = useSelector((state: RootState) => state.home.defaultTabName)
  if(defaultTabName){
    root = `/home/${defaultTabName}`
  }

  // always go to landing page if not logged in
  if(!isLoggedIn) root = '/lp'
  if(isExpiredToken) root = { screen: 'SignInMethods', params: { message: t('auth.expired') }}

  return (
    <View style={styles.container}>
      <Link testID='link-to-root'
        to={root}
        style={styles.link}
      >
        <AppIcon size={40} />
      </Link>
    </View>
  )
}

export default RootIcon
