import { useLinkProps } from '@react-navigation/native'
import { useState } from 'react'
import {
  Platform,
  TouchableOpacity,
  View,
  ViewStyle,
  Text,
} from 'react-native'

type NavigationObject = {
  screen: string
  params?: object
}
export type To = string | NavigationObject
const LinkButton = ({
  to,
  action,
  children,
  style,
  ...rest
}: {
  to: To,
  action?: any,
  style?: ViewStyle,
  children: React.ReactNode,
  testID?: string,
}) => {
  // @ts-ignore set correct navigation typescript
  const { onPress, ...props } = useLinkProps({ to, action });

  const [isHovered, setIsHovered] = useState(false);

  if (Platform.OS === 'web') {
    // It's important to use a `View` or `Text` on web instead of `TouchableX`
    // Otherwise React Native for Web omits the `onClick` prop that's passed
    // You'll also need to pass `onPress` as `onClick` to the `View`
    // You can add hover effects using `onMouseEnter` and `onMouseLeave`
    return (
      <View
        onClick={onPress}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={[
          {
            // @ts-ignore web style
            transition: 'opacity 0.3s',
            opacity: isHovered ? 0.5 : 1
          },
          style,
        ]}
        {...props}
        {...rest}
      >
        {children}
      </View>
    )
  }

  return (
    <TouchableOpacity onPress={onPress} style={style} {...props} {...rest}>
      {children}
    </TouchableOpacity>
  )
}

export default LinkButton
