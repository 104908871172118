import React from 'react'
import View from 'components/View'

import Dimensions from 'lib/Dimensions'
import Platform from 'lib/Platform'
import { wideThreshold } from 'styles'
import { getCurrentRoute } from 'navigation/RootNavigation'

const LayoutContainer = props => {

  const isWideDevice = Dimensions.get('window').width > wideThreshold
  const isWeb = Platform.OS === 'web'
  const route = getCurrentRoute()

  if(route?.name === 'Landing') return props.children

  if(isWideDevice && isWeb) {
    return(
      <View flex hcenter style={{
        backgroundColor: 'rgba(0,0,0,0.02)',
      }}>
        <View id='container-narrow' style={{
          width: wideThreshold,
          height: '100%',
          borderRight: '1px solid rgba(0,0,0,0.1)',
          borderLeft: '1px solid rgba(0,0,0,0.1)',
        }}>
          {props.children}
        </View>
      </View>
    )
  }else{
    return props.children
  }

}

export default LayoutContainer
